<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-form-group label="" label-for="">
            <label for="dtBaslangicFilter">
              {{ $t("Başlangıç Tarih") }}
            </label>
            <b-form-input
              type="date"
              v-model="dtBaslangicFilter"
             />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group>
            <label for="dtBitisFilter">
              {{ $t("Bitiş Tarih") }}
            </label>
            <b-form-input type="date" v-model="dtBitisFilter" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="" label-for="">
            <label for="musteriIdFilter">
            {{ $t('Müşteri Seçiniz') }}
            </label>
            <musteri-selection :musteri-select.sync="musteriIdFilter" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import MusteriSelection from '../selection/MusteriSelection.vue'

export default {
  components: {
    MusteriSelection,
  },
  computed: {
    dtBaslangicFilter: {
      get() {
        return this.$store.getters['cariEkstraYonetim/getCariEkstraFilter'].dtBaslangic
      },
      set(value) {
        this.$store.commit('cariEkstraYonetim/setCariEkstraFilter', { key: 'dtBaslangic', value })
      },
    },
    dtBitisFilter: {
      get() {
        return this.$store.getters['cariEkstraYonetim/getCariEkstraFilter'].dtBitis
      },
      set(value) {
        this.$store.commit('cariEkstraYonetim/setCariEkstraFilter', { key: 'dtBitis', value })
      },
    },
    musteriIdFilter: {
      get() {
        return this.$store.getters['cariEkstraYonetim/getCariEkstraFilter'].musteriId
      },
      set(value) {
        this.$store.commit('cariEkstraYonetim/setCariEkstraFilter', { key: 'musteriId', value })
      },
    },
  },
}
</script>
