<template>
  <div>
     <!-- Buttons -->
    <crud-button :onClickList="fetchCariEkstraList" :showAddNew="false" />
    <!-- Filters -->
    <cari-ekstra-filter />
    <b-card no-body class="p-2">
      <DxDataGrid
        id="gridContainer"
        :height="600"
        :ref="dataGridRefName"
        :data-source="cariEkstraList"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :column-auto-width="true"
        :selection="{ mode: 'single' }"
        :hover-state-enabled="true"
      >
        <DxPaging :enabled="false" />
        <DxColumn
          data-field="belgE_TARIH"
          caption="Belge Tarih"
          data-type="date"
          alignment="right"
          sort-order="desc"
        />
        <DxColumn
          data-field="belgE_NO"
          caption="Belge No"
        />
        <DxColumn
          data-field="carI_AD"
          caption="Cari Adı"
          width="150"
        />
        <DxColumn
          data-field="kaynaK_PROGRAM"
          caption="Kaynak"
        />
        <DxColumn
          data-field="vadE_TARIHI"
          caption="Vade Tarih"
          data-type="date"
          alignment="right"
        />
        <DxColumn
          data-field="parA_BIRIMI"
          caption="Kur"
        />
        <DxColumn
          data-field="borC_TUTAR"
          caption="Borç Tutar"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="alacaK_TUTAR"
          caption="Alacak Tutar"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="fark1"
          caption="Bakiye Tutar"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="doviZ_BORC_TUTAR"
          caption="YP Borç Tutar"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="doviZ_ALACAK_TUTAR"
          caption="YP Borç Tutar"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="fark2"
          caption="YP Bakiye Tutar"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxSummary>
          <DxTotalItem
            column="borC_TUTAR"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="alacaK_TUTAR"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="doviZ_BORC_TUTAR"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="doviZ_ALACAK_TUTAR"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
        </DxSummary>
      </DxDataGrid>
    </b-card>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSummary,
  DxTotalItem,
} from 'devextreme-vue/data-grid';
import { locale } from 'devextreme/localization';
import { customizeDecimalDxGrid } from '@core/utils/filter';
import CrudButton from '@/components/CrudButton.vue';
import CariEkstraFilter from './CariEkstraFilter.vue'

export default {
  components: {
    CrudButton,
    CariEkstraFilter,

    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSummary,
    DxTotalItem,
  },
  data() {
    return {
      currentFilter: 'auto',
      dataGridRefName: 'dataGrid',
    }
  },
 
  computed: {
    cariEkstraList() {
      let previousFark1 = 0;
      let previousFark2 = 0;
      return this.$store.getters['cariEkstraYonetim/getCariEkstras'].map(item => {
        item.fark1 = (item.borC_TUTAR - item.alacaK_TUTAR) + previousFark1
        previousFark1 = item.fark1

        item.fark2 = (item.doviZ_BORC_TUTAR - item.doviZ_ALACAK_TUTAR) + previousFark2
        previousFark2 = item.fark2
        return item
      })
    },
  },
  created() {
    locale(this.$i18n.locale);
  },
  methods: {
    fetchCariEkstraList() {
      const { dtBaslangic, dtBitis, musteriId } = this.$store.getters['cariEkstraYonetim/getCariEkstraFilter'];
      const query = { dtBaslangic, dtBitis, musteriId }
      this.$store.dispatch('cariEkstraYonetim/fetchCariEkstras', query)
    },
    customizeDecimalDxGrid,
  },
}
</script>

<style scoped>
#gridContainer {
  height: 440px;
}

.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option > span {
  margin-right: 10px;
}

.option > .dx-selectbox {
  display: inline-block;
  vertical-align: middle;
}
</style>
